var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"shows"},[_c('secondary-nav'),_c('div',{staticClass:"su-body-shows d-flex justify-content-center"},[_c('div',{staticClass:"shows-section"},[_c('div',{staticClass:"container d-flex justify-content-center"},[_c('div',{staticClass:"col-1 void-column"}),_c('div',{staticClass:"col-md-11 col-12 row"},[_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":815640386,"options":{
                      pip: false,
                    }}})],1),_vm._m(0)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":815640853,"options":{
                      pip: false,
                    }}})],1),_vm._m(1)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":815641238,"options":{
                      pip: false,
                    }}})],1),_vm._m(2)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":815641575,"options":{
                      pip: false,
                    }}})],1),_vm._m(3)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":815641925,"options":{
                      pip: false,
                    }}})],1),_vm._m(4)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819557348,"options":{
                      pip: false,
                    }}})],1),_vm._m(5)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819557951,"options":{
                      pip: false,
                    }}})],1),_vm._m(6)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819559007,"options":{
                      pip: false,
                    }}})],1),_vm._m(7)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819559787,"options":{
                      pip: false,
                    }}})],1),_vm._m(8)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819561966,"options":{
                      pip: false,
                    }}})],1),_vm._m(9)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":819562607,"options":{
                      pip: false,
                    }}})],1),_vm._m(10)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":837593952,"options":{
                      pip: false,
                    }}})],1),_vm._m(11)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":837592787,"options":{
                      pip: false,
                    }}})],1),_vm._m(12)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":837591506,"options":{
                      pip: false,
                    }}})],1),_vm._m(13)])])]),_c('div',{staticClass:"col-lg-4 col-12 pl-2 pr-2"},[_c('div',{staticClass:"marquee-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('vimeo-player',{staticClass:"video-player w-auto",attrs:{"video-id":837590571,"options":{
                      pip: false,
                    }}})],1),_vm._m(14)])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("One night only")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Solid Rock")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Cool")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Belle Epoque")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Fantasy Woods")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Allegro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Beautiful")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Coast to Coast")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("One day more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Ritmo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Top Hits")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Quick")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Paz")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Nature")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title-card"},[_c('span',[_vm._v("Mediterraneo")])])
}]

export { render, staticRenderFns }