<template>
  <section class="shows">
    <secondary-nav></secondary-nav>

    <div class="su-body-shows d-flex justify-content-center">
      <div class="shows-section">
        <div class="container d-flex justify-content-center">
          <div class="col-1 void-column"></div>

          <div class="col-md-11 col-12 row">
            <!--
            <div class="col-md-4 col-12 vertical-scroll-column pt-4">
                <div class="marquee-card">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                    </div>
                    <div class="col-12 title-card">
                      <span>Primo video</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                  </div>
                </div>
                <div class="marquee-card">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                    </div>
                    <div class="col-12 title-card">
                      <span>Primo video</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                  </div>
                </div>
                <div class="marquee-card">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                    </div>
                    <div class="col-12 title-card">
                      <span>Primo video</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                  </div>
                </div>
                <div class="marquee-card">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                    </div>
                    <div class="col-12 title-card">
                      <span>Primo video</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                  </div>
                </div>
                <div class="marquee-card">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                    </div>
                    <div class="col-12 title-card">
                      <span>Primo video</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                    <div class="col-6 sub-info-card">
                      <span>28/04/2016</span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-4 col-12 vertical-scroll-column pt-4">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12 vertical-scroll-column pt-4">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player :video-id="786334291" class="video-player w-auto"></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Primo video</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                  <div class="col-6 sub-info-card">
                    <span>28/04/2016</span>
                  </div>
                </div>
              </div>
            </div>
            -->

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815640386"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>One night only</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815640853"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Solid Rock</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815641238"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Cool</span>
                  </div>
                  <!--
                  <div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>Msc Meraviglia</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815641575"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Belle Epoque</span>
                  </div>
                  <!--
                  <div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Seashore</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815641925"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Fantasy Woods</span>
                  </div>
                  <!--
                  <div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>Msc Seashore</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819557348"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Allegro</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819557951"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Beautiful</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819559007"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Coast to Coast</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819559787"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>One day more</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819561966"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Ritmo</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819562607"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Top Hits</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="837593952"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Quick</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="837592787"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Paz</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="837591506"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Nature</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="837590571"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Mediterraneo</span>
                  </div>
                  <!--<div class="col-6 sub-info-card">
                    <span></span>
                  </div>
                  <div class="col-6 place-info-card">
                    <span>MSC Bellissima</span>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";
import VueMarqueeSlider from 'vue-marquee-slider';
import vueVimeoPlayer from 'vue-vimeo-player';

export default Vue.extend({
  name: 'shows',
  metaInfo: {
    title: 'Our Shows',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
  },
});
Vue.use(VueMarqueeSlider);
Vue.use(vueVimeoPlayer);
</script>
